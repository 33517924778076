/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    var animating = false;

    var $fancyBox = null;
    var $magnify = null;

    var getScrollOffsetTop = function () {
        return -1 * ($('header .portraits').height() + 45);
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                svgReplace('img.svg', 'data-src');

                //equalHeightColumns();

                if(isMobile){
                    $('body').addClass('mobile');
                }

                $(window).load(function () {
                    var hash = window.location.href.split('#!/')[1];
                    if (hash !== '' && hash !== '1') {
                        animating = true;

                        setTimeout(function() {
                            $.scrollTo($('a#' + hash), 500, {
                                offset: {
                                    top: getScrollOffsetTop()
                                },
                                onAfter: function () {
                                    animating = false;
                                },
                                axis: 'y'
                            });
                        }, 600);
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                var setupWaypoints = function () {

                    var anchors = $("a[id]:not([href])");
                    var className = 'anchor-current';

                    Waypoint.destroyAll();

                    $(anchors).each(function (index, value) {
                        $anchor = value;
                        new Waypoint({
                            element: document.getElementById($anchor.id),
                            handler: function (direction) {
                                var $active = $(this.element);

                                var pos = index;

                                $(anchors).removeClass(className);

                                if (pos > 0 && pos < anchors.length && direction === "up") {
                                    $active = $(anchors.get(pos - 1));
                                }

                                $active.addClass(className);

                                if ($active[0].id === "2" && $('footer').is(":visible")) {
                                    $(".navbar-header").addClass("over-info");
                                } else {
                                    $(".navbar-header").removeClass("over-info");
                                }
                            },
                            offset: "28%"
                        });
                    });
                };

                if(isMobile){
                    $('footer').hide();
                }

                $(window).on('resize load', function () {
                    var $backgroundImage = $('.row-background-image .img-background').first();
                    var backgroundImageHeight = $backgroundImage.height();
                    var windowHeight = $(window).height();

                    if (backgroundImageHeight > windowHeight) {
                        var backgroundImageHeightMargin = backgroundImageHeight - windowHeight;
                        $backgroundImage.parent().css('margin-top', '{0}px'.format(-(backgroundImageHeightMargin)));
                    } else {
                        $backgroundImage.parent().css('margin-top', '0px');
                    }

                    $backgroundImage.parent().css('visibility', 'visible');

                    if((!isMobile)){
                        setupWaypoints();
                    }else{
                        $('.portrait').addClass('hover');
                    }
                });

                $('#main-background-map').imageMapResize().promise().done(function(){
                    setTimeout(function(){
                        //poprawka imageMapResize - problem przy ładowaniu strony
                        var event; // The custom event that will be created

                        if (document.createEvent) {
                            event = document.createEvent("HTMLEvents");
                            event.initEvent("resize", true, true);
                        } else {
                            event = document.createEventObject();
                            event.eventType = "resize";
                        }

                        event.eventName = "resize";

                        if (document.createEvent) {
                            window.dispatchEvent(event);
                        } else {
                            window.fireEvent("on" + event.eventType, event);
                        }

                    }, 500);
                });

                var anchors = $("a[id]:not([href])");
                var className = 'anchor-current';

                $(anchors).removeClass(className);
                $(anchors[0]).addClass(className);

                if(!isMobile) {
                    $('a#btn-info').click(function (e) {
                        e.preventDefault();
                        animating = true;

                        $.scrollTo($('a#2'), 500, {
                            offset: {
                                top: getScrollOffsetTop()
                            },
                            onAfter: function () {
                                animating = false;
                            },
                            axis: 'y'
                        });
                    });
                }

                $('.portrait, map#main-background-map area').mouseover(function () {
                    $(".row-background-image img.img-background[data-subpage-id='" + $(this).data('subpageId') + "']").addClass('visible');

                    $(".portrait[data-subpage-id='" + $(this).data('subpageId') + "']").addClass('hover');
                });

                $('.portrait, map#main-background-map area').mouseout(function () {
                    $(".row-background-image img.img-background[data-subpage-id='" + $(this).data('subpageId') + "']").removeClass('visible');

                    $(".portrait[data-subpage-id='" + $(this).data('subpageId') + "']").removeClass('hover');
                });

                $('.brand.navbar-brand').click(function (e) {
                    e.preventDefault();

                    $('html, body').animate({scrollTop: 0}, 800);
                    return false;
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_template_subpage': {
            init: function () {
                // JavaScript to be fired on the about us page

                $('.gallery-item img').each(function (index, item) {
                    $(item).attr('data-src', $(item).attr('src'));

                    var imgFull = $(item).attr('src').replace(/-\d{1,5}x\d{1,5}/gi, '');

                    $(item).attr('data-magnify-src', imgFull);

                    $(item).removeAttr('src');
                });

                $('section').each(function (index, item) {
                    if($(item).find(':first-child').hasClass('text')){
                        var div = $(item).find('.image:first');
                        var clone = div.clone();

                        div.addClass('hidden-xs');
                        clone.addClass('hidden-sm hidden-md hidden-lg');
                        clone.prependTo(item);
                    }
                });

                $('section').each(function (index, item) {
                    $(item).find('img').attr('rel', 'gallery-{0}'.format(index));
                    $(item).find('.see-more-link').attr('rel', 'gallery-{0}'.format(index));
                    $(item).find('.read-more-link').attr('rel', 'text-{0}'.format(index));
                    $(item).prepend('<a id="{0}"></a>'.format(index + 1));
                });

                $(".popup.gallery").each(function (index, item) {
                    var $popup = $(this);

                    $popup.find('.gallery-item').each(function (index2, item) {
                        var id = '{0}-{1}-{2}'.format('gallery', index, index2);
                        $(item).attr('id', id);
                        $popup.append('<a href="#{0}" rel="{1}"></a>'.format(id, '{0}-{1}'.format('gallery', index)));
                    });
                });

                $(".popup.text").each(function (index, item) {
                    var $popup = $(this);

                    $popup.find('.gallery-text-item').each(function (index2, item) {
                        var id = '{0}-{1}-{2}'.format('text', index, index2);
                        $(item).attr('id', id);
                        $popup.append('<a href="#{0}" rel="{1}"></a>'.format(id, '{0}-{1}'.format('text', index)));
                    });
                });

                var anchors = $("a[id]:not([href])");
                var className = 'active';

                $(anchors).removeClass(className);
                $(anchors[0]).addClass(className);

                $($('.navigation-group .navigation-item')[0]).addClass(className);

                Waypoint.destroyAll();

                $(anchors).each(function (index, value) {
                    $anchor = value;
                    new Waypoint({
                        element: document.getElementById($anchor.id),
                        handler: function (direction) {
                            var $active = $(this.element);

                            var pos = index;

                            $(anchors).removeClass(className);

                            if (pos > 0 && pos < anchors.length && direction === "up") {
                                pos = pos - 1;
                                $active = $(anchors.get(pos - 1));
                            }

                            $active.addClass(className);

                            //window.location.hash = '#!/{0}'.format(pos + 1);

                            $('.navigation-group .navigation-item').removeClass(className);
                            $($('.navigation-group .navigation-item')[pos]).addClass(className);
                        },
                        offset: "70%"
                    });
                });

                $('.navigation-group .navigation-item').each(function (index, item) {
                    $(item).addClass('tooltipster-tooltip');
                    var sectionTitle = $('section').eq(index).find('p.section-header').text();

                    $(item).attr('title', sectionTitle);
                });

                var setupPopupSize = function(timeout){

                    timeout = timeout || 400;

                    if($fancyBox === null) {
                        return;
                    }

                    var primary = $($fancyBox.content).find('.gallery-primary-text');
                    var secondary = $($fancyBox.content).find('.gallery-secondary-text');

                    var textHeight = $(primary).or(secondary).parent().height() * 2;

                    var image = $($fancyBox.content).find('img').eq(0);

                    //console.log(primary);
                    //console.log(secondary);
                    //console.log('textHeight: ', textHeight);

                    //if($(image).data('loaded') === false){
                    //    setTimeout(setupPopupSize, 100);
                    //}

                    var fancyboxHeight = $($fancyBox.content).parent().height();
                    var fancyboxWidth = $($fancyBox.content).parent().width();

                    if(fancyboxHeight < fancyboxWidth){
                        $(image).height($($fancyBox.content).parent().height() - textHeight);
                        $(image).width('auto');
                    }else{
                        $(image).width('100%');
                        $(image).height('auto');
                    }

                    setTimeout(function(){
                        $.fancybox.update();
                        $.fancybox.hideLoading();
                        $('.fancybox-nav').show();

                        $($fancyBox.content).find('.magnifier-icon').stop().hide();

                        if($($fancyBox.content).find('.magnify').length === 0){

                            $(image).magnify(
                                {
                                    'onload': function(){
                                        console.log('magnify onload');

                                        if($($fancyBox.content).find('.magnifier-icon').length === 0) {
                                            $('<div class="magnifier-icon"></div>')
                                                .hide()
                                                .appendTo($($fancyBox.content).find('.magnify'))
                                                .fadeIn();
                                        }
                                        else{
                                            $($fancyBox.content).find('.magnifier-icon').fadeIn();
                                        }
                                    }
                                }
                            );
                        }
                        else{
                            setTimeout(function(){
                                $($fancyBox.content).find('.magnifier-icon').fadeIn();
                            }, 100);
                        }

                        var $content = $($fancyBox.content);
                        $content.parents().eq(2).css('visibility', 'visible');

                    }, timeout);
                };

                //$(window).on('resize', function () {
                //    setupPopupSize();
                //});

                var baseFancyBoxOptions = {
                    'helpers': {
                        title: null
                    },
                    tpl : {
                        closeBtn : '<a title="" class="fancybox-item fancybox-close" href="javascript:;"></a>',
                        next     : '<a title="" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                        prev     : '<a title="" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
                    }
                };

                $(".see-more-link, img[rel]").on("click", function () {
                    var el = $(this).attr("rel");

                    var options = {
                        //'autoSize': false,
                        'scrolling': "no",
                        'fitToView': false,
                        'autoSize': false,
                        width: '98%',
                        height: '97%',
                        'afterClose': function() {
                            //console.log('afterClose');
                            $.fancybox.hideLoading();
                            $fancyBox = null;

                            if($magnify !== null){
                                $magnify.destroy();
                            }
                        },
                        'beforeShow' : function() {
                            //console.log('beforeShow');

                            var $content = $(this.content);

                            $.fancybox.showLoading();

                            $content.parents().eq(2).css('visibility', 'hidden');
                        },
                        'beforeChange': function() {

                        },
                        'beforeLoad' : function() {
                            //console.log('beforeLoad');
                            var fancy = this;
                            $fancyBox = fancy;
                            var $content = $(this.content);

                            $content.find('img').each(function (index, item) {
                                var dataAttrValue = $(item).data('src');
                                if(dataAttrValue !== undefined) {
                                    $(item).removeData('src');
                                    $(item).removeAttr('data-src');
                                    $(item).load(function() {
                                        $(item).data('loaded', true);

                                        $(item).unbind("load");
                                    });

                                    $(item).error(function() {
                                        $(item).hide();
                                        fancy.width = window.innerWidth * 0.7;
                                        fancy.height = window.innerHeight * 0.9;

                                        $.fancybox.hideLoading();
                                        $('.fancybox-nav').show();

                                        var $content = $(fancy.content);
                                        $content.parents().eq(2).css('visibility', 'visible');
                                    });

                                    $(item).attr('src', dataAttrValue);
                                }
                            });
                        },
                        'afterShow' : function() {
                            //console.log('afterShow');

                            setupPopupSize();
                        },
                    };

                    $.extend(options, baseFancyBoxOptions);

                    $('a[rel="' + el + '"]').fancybox(options).eq(0).trigger('click');

                    return false;
                });

                $(".read-more-link").on("click", function () {
                    var el = $(this).attr("rel");

                    var options = {
                        'closeBtn': false,
                        'fitToView': false,
                        'autoSize': false,
                        'width': isMobile ? '95%' : '70%',
                        'height': '90%'
                    };

                    $.extend(options, baseFancyBoxOptions);

                    $('a[rel="' + el + '"]').fancybox(options).eq(0).trigger('click');

                    return false;
                });

                $(".read-less-link").on("click", function () {
                    $.fancybox.close();
                    $(document).unbind('click.fb-start');

                    return false;
                });

                $('.tooltipster-tooltip').tooltipster({
                    'position': ['left'],
                    'trigger': 'hover',
                    'theme': 'tooltipster-default tooltipster-custom',
                    'offsetX': 10
                });

                $('.navigation-group .navigation-item').click(function (e) {
                    e.preventDefault();
                    animating = true;

                    var target = anchors.get($(this).index());

                    $.scrollTo(target, 500, {
                        offset: {
                            top: getScrollOffsetTop()
                        },
                        onAfter: function () {
                            animating = false;
                        },
                        axis: 'y'
                    });
                });

                $('html').on({
                    magnifystart: function() {
                        $('.fancybox-opened .magnifier-icon').stop().fadeOut();
                    },
                    magnifyend: function() {
                        $('.fancybox-opened .magnifier-icon').stop().fadeIn();
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on the about us page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
